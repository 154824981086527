@mixin remove-top-bottom-child-spacing {
  & {
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
