.btn {
  border: 0;
  padding: 0;
  background: none transparent;
  cursor: pointer;
  line-height: 1;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
