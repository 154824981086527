svg {
  display: block;
}

html {
  scroll-behavior: smooth;
  // https://stackoverflow.com/a/56467997
  scroll-padding-top: var(--header-height);
}

html,
body,
#__next,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: var(--white);
  background-color: var(--black);
  font-family: "Inter", sans-serif;
  line-height: 1.33334;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--light-teal);
}

#app {
  display: flex;
  flex-direction: column;
  position: relative;

  > header {
    height: var(--header-height);
    background-color: #101010;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .track {
      padding: 0 var(--edge-gutter);
      height: 100%;

      > :first-child {
        margin-right: auto;
      }

      > * + * {
        margin-left: 4px;
      }
    }
  }

  > main {
    padding: var(--header-height) 0 var(--footer-height);
    display: flex;
    flex-direction: column;
    @include remove-top-bottom-child-spacing;
  }

  > footer {
    height: var(--footer-height);
    border-top: 1px solid #999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.track-item {
  flex: 0 1 auto;
}

.icon {
  display: flex;
  align-items: center;

  &[hidden] {
    display: none;
  }
}

.menu {
  position: relative;

  .menu-button {
    display: flex;
    align-items: center;
    color: #ccc;
    margin-left: 8px;

    .logo,
    .menu-button-icon {
      transition: transform var(--transition-time) var(--transition-fx);
    }

    .logo {
      height: 2rem;
      margin-left: 4px;
    }

    &[aria-expanded="true"] {
      .menu-button-icon,
      .logo {
        transform: rotate(180deg);
      }
    }
  }
}

.menu-drawer {
  width: 100%;
  background: #4e4e4e;
  padding: 0 var(--edge-gutter);
  top: var(--header-height);
  position: absolute;
  overflow-y: hidden;
  transition: height 0.5s var(--transition-fx);
  box-shadow: 0 6px 5px 4px rgba(0, 0, 0, 0.5);

  > :first-child {
    margin-top: 18px;
  }

  > :last-child {
    margin-bottom: 18px;
  }

  &[hidden] {
    display: block;
    height: 0;
    box-shadow: none;
  }
}

.filters {
  display: flex;
}

.filters-title {
  margin: 0;
  font-size: 1em;
  margin-right: 1ch;
}

.filters-list {
  list-style: none;
  display: contents;
  font-size: 1em;

  > li + li {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid var(--black);
  }

  a {
    text-decoration: none;
    opacity: 0.4;
    display: inline-flex;
    align-items: center;

    .filter-action-icon {
      margin-left: 0.5ch;
    }

    &.filter-current {
      opacity: 1;

      .filter-action-icon {
        fill: currentColor;
      }
    }
  }
}

.filter-status-icons {
  .filter-status-icon {
    fill: currentColor;
    opacity: 0.7;
    transform: translateX(0);
    transition: opacity var(--transition-time) var(--transition-fx),
      transform var(--transition-time) var(--transition-fx);
  }

  &[hidden] {
    display: block;

    .filter-status-icon {
      opacity: 0;
      transform: translateX(5px);
    }
  }
}

.main-title {
  flex-basis: 80%;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  max-height: 100%;
}

.main-title-image {
  display: block;
  width: 100%;
  max-width: 500px;
  max-height: calc(var(--header-height) - 24px);
  object-fit: contain;
  object-position: left center;
}

.event-date {
  margin: 1em var(--edge-gutter);
  text-transform: capitalize;
}

.address {
  margin: 1em var(--edge-gutter);
}

.address-title {
  margin-bottom: 0;
  font-size: 16px;
}

.address-content {
  margin-top: 0;
}

.date-heading {
  position: sticky;
  top: var(--header-height);
  margin: 0;
  padding: 0.5em var(--edge-gutter);
  background: var(--teal);

  .filter-bookmarks & {
    display: none;

    &:has(~ .schedule-item.is-bookmarked) {
      display: revert;
    }
  }
}
