html {
  --header-height: 3rem;
  --footer-height: 3rem;
  --light-teal: #27e2ea;
  --teal: #0b4d4f;
  --burgundy: #320a05;
  --white: #eee;
  --black: #222;
  --edge-gutter: 12px;
  --transition-time: 0.2s;
  --transition-fx: ease-in-out;

  @media only screen and (min-width: 400px) {
    --edge-gutter: 18px;
  }
}
