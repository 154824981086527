.date-jump-bar {
  ol {
    background: var(--burgundy);
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
  }

  li {
    flex-grow: 1;
  }

  a {
    height: var(--footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;

    &.current {
      background: var(--teal);
      color: #fff;
    }

    &:focus {
      outline: 0;
    }
  }

  li + li > a {
    border-left: 1px solid #ccc;
  }

  .utilities {
    display: flex;
    flex-direction: column;

    a {
      height: 50%;
      padding-top: 0.49em;
      padding-bottom: 0.49em;
      height: auto;

      &.now {
        border-top: 1px solid #fff;
      }
    }
  }
}
