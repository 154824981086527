.schedule-item {
  transition: background-color 0.25s ease-in-out;
  padding: 1.5rem var(--edge-gutter);

  + .schedule-item {
    border-top: 1px solid var(--white);
  }

  @include remove-top-bottom-child-spacing;

  &.is-bookmarked {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .filter-bookmarks &:not(.is-bookmarked) {
    display: none;
  }
}

.schedule-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.schedule-item-heading {
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 1.33334rem;
  margin: 0;
}

.schedule-item-bookmark-action {
  color: #e5c693;
  fill: transparent;
  transition: fill 0.25s ease-in-out;

  &[aria-pressed="true"] {
    fill: #e5c693;
  }
}

.schedule-item-detail {
  > div {
    display: flex;
  }

  dt {
    font-weight: bold;

    &:after {
      content: ":\A0";
    }
  }

  dd {
    margin-left: 0;
  }
}
